import {React,useState, useEffect,useRef,useCallback} from 'react'
import './home.css'
import { useNavigate } from 'react-router-dom'; 
import Axios from 'axios'
import Entry from '../../components/entry/Entry';
import Topbar from '../../components/topbar/Topbar';




export default function Home() {
    const navigate = useNavigate();
    const[storename,setStorename] = useState('');
    const[storeid, setStoreid] = useState("");
    const [storeLocation, setStorelocation] = useState('')


    Axios.defaults.withCredentials = true;
    let mail = ''
    useEffect(() =>{
      Axios.get('https://panel.yunshuglobal.com:3052/api/profile').then((response)=>{
        if(response.data.valid){
        setStoreid(response.data.storeid)
        }
        else{
          //pass
          navigate('/')
        }
      
      })
    },[])

    useEffect(()=>{
        Axios.get(`https://panel.yunshuglobal.com:3052/api/getStoredetails?storeid=${storeid}`).then((res)=>{
            setStorename(res.data.storename)
            setStorelocation(res.data.storelocation)
        })
    },[storeid])
  return (
    <div className='homeWrapper'>
       
        <Topbar storeid={storename} />
        <Entry storeid={storename} storeLocation={storeLocation} />
    </div>
  )
}
